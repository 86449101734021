import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  Link,
  Button,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const ScamAlertModal = () => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const hasSeenPopup = localStorage.getItem("seenScamAlert");
    if (!hasSeenPopup) {
      setOpen(true);
    }
  }, []);

  const handleClose = () => {
    setOpen(false);
    localStorage.setItem("seenScamAlert", "true");
  };

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
      maxWidth="md"
    >
      <DialogTitle
        sx={{ fontWeight: "bold", fontSize: "1.5rem", textAlign: "center" }}
      >
        SCAM ALERT
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Typography
          variant="body2"
          sx={{
            mb: 1,
            textAlign: "justify",
          }}
        >
          It has come to our attention that certain unknown individuals are
          posing as employees or agents of Eversub India Private Limited
          (“EIPL”) or Subway® India and advertising franchising opportunities,
          business solicitation, earning schemes, etc. (hereinafter referred to
          as “Schemes”), by reaching out to the public through fake websites /
          email addresses – e.g. - contact@subwayspartner.com,
          contact@subwaypartnerindia.com, contact@subwayindia.com,
          info@subwayspartnersindia.com, info@subwaysystemsindiapvtltd.com etc.
          – and fake profiles on WhatsApp, Facebook, Instagram, and other social
          media platforms (the “Fraudulent Accounts”).
        </Typography>

        <Typography
          variant="body2"
          sx={{
            mb: 1,
            textAlign: "justify",
          }}
        >
          These unscrupulous Fraudulent Accounts may circulate fake and
          erroneous Schemes and solicit investments through emails and various
          social media platforms, including but not limited to WhatsApp, or
          email claiming that such Schemes are being promulgated by EIPL. Such
          Schemes require people to register / enrol on the fake websites and
          submit 'franchisee fee' for business opportunities. Thereafter, they
          require payment of a sum of money to complete the registration, post
          which the registered person is purpotedly guaranteed Subway®
          franchisee rights. These Fraudulent Accounts are using Subway®
          registered trade name and brand name of Subway® to target unsuspecting
          individuals and tarnish the name of EIPL and the Subway®.
        </Typography>
        <Typography
          variant="body2"
          sx={{
            mb: 1,
            textAlign: "justify",
          }}
        >
          Please be informed that EIPL DOES NOT offer or solicit any such Scheme
          or require any person to deposit any amount with it, and its employees
          and representatives never solicit the same, or otherwise reach out to
          the public through emails, social media platforms and messaging apps
          such as WhatsApp, Facebook, Instagram to seek prospective franchisees
          of Subway®.
        </Typography>
        <Typography
          variant="body2"
          sx={{
            mb: 1,
            textAlign: "justify",
          }}
        >
          These individuals and Fraudulent Accounts operated by them are NOT
          associated with EIPL / Subway® India, Culinary Brands India Private
          Limited, the Culinary Brands Group or any of their respective
          employees. The firm does not operate any such WhatsApp Group to share
          franchising opportunities, earning schemes or invite capital or
          subscription fee / franchisee fee from the general public. The public
          in general is advised to not be a part of any such fraudulent WhatsApp
          groups, respond to any solicitations, or share and divulge any
          personal and confidential information, as the same shall enable the
          perpetrators to give effect to the fraud.
        </Typography>

        <Typography
          variant="body2"
          sx={{
            mb: 1,
            textAlign: "justify",
          }}
        >
          While EIPL is actively pursuing legal recourse against these
          wrongdoers, the public is advised and encouraged to be vigilant and
          aware of such fake communications soliciting money and promising
          franchisee opportunities under the name of EIPL (or its erstwhile
          name, Subway Systems India Private Limited). If you come across any
          such fraudulent websites, emails, mobile application, or WhatsApp and
          other messaging app groups using Subway® or EIPL's name, you are
          advised to immediately report the same to notices@subway.in , and
          approach the cyber cell with jurisdiction over your area with all
          details such as admin phone numbers, UPI handles and bank account
          details and refrain from depositing any money or sharing any personal
          information.
        </Typography>
        <Typography
          variant="body2"
          sx={{
            mb: 1,
            textAlign: "justify",
          }}
        >
          EIPL, its affiliates, and employees will not be responsible and liable
          for any kind of losses or damage suffered by anyone as a result of
          dealing with any fraudsters purporting to represent EIPL.
        </Typography>
        <Typography variant="body2" sx={{ fontWeight: "bold" }}>
          Eversub India Private Limited
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

export default ScamAlertModal;
